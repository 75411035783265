import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { OptionFieldProps } from '../components/form/DropdownField';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

import axios from 'axios';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const RuleOptionsContext = createContext({
  serviceOptions: [] as OptionFieldProps[],
  goalOptions: [] as OptionFieldProps[],
  speciesOptions: [] as OptionFieldProps[],
});

export function useRuleOptions() {
  const context = useContext(RuleOptionsContext);
  if (!context) {
    throw new Error('useRuleOptions must be used within a RuleOptionsProvider');
  }
  return context;
}

interface RuleOptionsProviderProps {
  children: ReactNode;
}

/**
 * Populates the options for rules used by the personalization component
 * such as services, and pet species.
 */
export const RuleOptionsProvider = ({ children }: RuleOptionsProviderProps) => {
  const isEE = useExperienceEditor();
  const {
    sitecoreContext: { site },
  } = useSitecoreContext();
  const siteName = site?.name;
  // temporary way of viewing the experience editor while developing locally (set isEditor to true in localStorage)
  const isDevEE = typeof window !== 'undefined' && localStorage.getItem('isEditor') === 'true';
  const isEditor = isDevEE || isEE;
  const [serviceOptions, setServiceOptions] = useState<OptionFieldProps[]>([]);
  const [goalOptions, setGoalOptions] = useState<OptionFieldProps[]>([]);
  const [speciesOptions, setSpeciesOptions] = useState<OptionFieldProps[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isEditor) {
      return;
    }
    if (isLoaded) {
      return;
    }

    async function fetchServices() {
      const servicesResponse = await axios.get<{ data: OptionFieldProps[] }>(
        `${process.env.PUBLIC_URL}/api/personalization/fetchServices?siteName=${siteName}`
      );
      setServiceOptions(servicesResponse.data.data);
    }

    async function fetchGoals() {
      const goalsResponse = await axios.get<{ data: OptionFieldProps[] }>(
        `${process.env.PUBLIC_URL}/api/personalization/fetchGoals?siteName=${siteName}`
      );
      setGoalOptions(goalsResponse.data.data);
    }

    async function fetchSpecies() {
      const servicesResponse = await axios.get<{ data: OptionFieldProps[] }>(
        `${process.env.PUBLIC_URL}/api/personalization/fetchSpecies`
      );
      setSpeciesOptions(servicesResponse.data.data);
    }

    async function fetchData() {
      await Promise.all([fetchServices(), fetchGoals(), fetchSpecies()]);
      setIsLoaded(true);
    }
    fetchData();
  }, [isEditor, isLoaded]);

  return (
    <RuleOptionsContext.Provider value={{ serviceOptions, goalOptions, speciesOptions }}>
      {children}
    </RuleOptionsContext.Provider>
  );
};
